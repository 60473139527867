<template>
  <b-container>
    <b-row>
      <b-col md="4">
        <b-card class="text-center">
          <b-avatar
            size="150"
            variant="light-primary"
            :src="images.profile"
            class="badge-minimal"
            badge-variant="success"
          />
          <div class="mt-2" v-if="form.verified == 1">
            <b-badge variant="light-success"> Terverifikasi </b-badge>
          </div>
          <b-button
            v-if="this.images.identity != null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-2"
            v-b-modal.modal-photo-id
          >
            Foto KTP
          </b-button>
          <b-button 
            v-if="this.images.identity == null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-2"
            @click="notAvail()"
          >
            Foto KTP
          </b-button>
          <b-button
            v-if="this.images.license != null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-1"
            v-b-modal.modal-photo-license
          >
            Foto SIM
          </b-button>
          <b-button
            v-if="this.images.license == null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-1"
            @click="notAvail()"
          >
            Foto SIM
          </b-button>
          <b-button
            v-if="this.images.npwp != null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-1"
            v-b-modal.modal-photo-npwp
          >
            Foto NPWP
          </b-button>
          <b-button
            v-if="this.images.npwp == null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-1"
            @click="notAvail()"
          >
            Foto NPWP
          </b-button>
          <b-button
            v-if="this.images.company != null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-1"
            v-b-modal.modal-photo-company
          >
            Foto Id Perusahaan
          </b-button>
          <b-button
            v-if="this.images.company == null"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            class="mt-1"
            @click="notAvail()"
          >
            Foto Id Perusahaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            block
            class="mt-1"
            v-if="form.verified == 0"
            @click="verified()"
          >
            Verifikasi
          </b-button>
          <b-modal
            id="modal-photo-id"
            ok-only
            ok-title="close"
            centered
            size="lg"
            title="Foto"
            class="text-center"
          >
            <b-img id="photo" :src="images.identity" width="1000px" fluid />
          </b-modal>
          <b-modal
            id="modal-photo-license"
            ok-only
            ok-title="close"
            centered
            size="lg"
            title="Foto"
            class="text-center"
          >
            <b-img id="photo" :src="images.license" width="1000px" fluid />
          </b-modal>
          <b-modal
            id="modal-photo-npwp"
            ok-only
            ok-title="close"
            centered
            size="lg"
            title="Foto"
            class="text-center"
          >
            <b-img id="photo" :src="images.npwp" width="1000px" fluid />
          </b-modal>
          <b-modal
            id="modal-photo-company"
            ok-only
            ok-title="close"
            centered
            size="lg"
            title="Foto"
            class="text-center"
          >
            <b-img id="photo" :src="images.company" width="1000px" fluid />
          </b-modal>
        </b-card>
        <b-card>
          <b-row>
            <b-col>
              <b-form-group label="Assign PIC" label-for="pic-assign">
                <v-select
                  id="customer"
                  v-model="form.selectedCustomer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsCustomer"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedCustomer"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedOrganization }}</strong> -->
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                block
                class="mt-1"
                @click="assign()"
              >
                Perbaharui
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card>
          <b-row>
            <b-col>
              <p class="font-weight-bolder">Informasi Pribadi</p>
            </b-col>
            <b-col class="text-right">
              <b-badge :variant="statusVariant(this.form.status)" class="mb-1">
                <span v-if="this.form.status === 0">Tidak Aktif</span>
                <span v-else-if="this.form.status === 1">Aktif</span>
              </b-badge>
            </b-col>
          </b-row>
          <b-form v-on:submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group label="Nama" label-for="name">
                  <b-form-input
                    id="name"
                    placeholder="Nama"
                    v-model="form.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label="NIK" label-for="nik">
                  <b-form-input
                    id="nik"
                    placeholder="NIK"
                    v-model="form.nik"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="No Telp" label-for="phone">
                  <b-form-input
                    id="phone"
                    placeholder="No Telp"
                    v-model="form.phone"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    placeholder="Email"
                    v-model="form.email"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Jenis Kelamin" label-for="gender">
                  <b-form-input
                    id="gender"
                    placeholder="Jenis Kelamin"
                    v-model="form.gender_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tempat Lahir" label-for="birthplace">
                  <b-form-input
                    id="birthplace"
                    placeholder="Tempat Lahir"
                    v-model="form.birthplace"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tanggal Lahir" label-for="birthdate">
                  <b-form-input
                    id="birthdate"
                    placeholder="Tanggal Lahir"
                    v-model="form.birthdate"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col md="12">
                <b-form-group label="Alamat Lengkap" label-for="address">
                  <b-form-textarea
                    id="address"
                    placeholder="Alamat Lengkap"
                    v-model="address.address"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Kelurahan" label-for="village">
                  <b-form-input
                    id="village"
                    placeholder="Kelurahan"
                    v-model="address.village"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Kecamatan" label-for="district">
                  <b-form-input
                    id="district"
                    placeholder="Kecamatan"
                    v-model="address.district"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Kota / Kabupaten" label-for="city">
                  <b-form-input
                    id="city"
                    placeholder="Kota / Kabupaten"
                    v-model="address.city"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Provinsi" label-for="province">
                  <b-form-input
                    id="province"
                    placeholder="Provinsi"
                    v-model="address.province"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Negara" label-for="country">
                  <b-form-input
                    id="country"
                    placeholder="Negara"
                    v-model="address.country"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Kode Pos" label-for="postal_code">
                  <b-form-input
                    id="postal_code"
                    placeholder="Kode Pos"
                    v-model="address.postal_code"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit and reset -->
              <b-col
                md="6"
                class="mt-2"
                v-if="permissions.includes('sales-customer-update')"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="updateCustomerPlusValidation()"
                  block
                >
                  Perbaharui
                </b-button>
              </b-col>
              <b-col
                md="6"
                class="mt-2"
                v-if="permissions.includes('sales-customer-delete')"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="danger"
                  class="mr-1"
                  @click="deleteCustomer()"
                  block
                >
                  Hapus
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card v-if="form.company_id != null">
          <b-row>
            <b-col>
              <p class="font-weight-bolder">Informasi Perusahaan</p>
            </b-col>
          </b-row>
          <b-form v-on:submit.prevent>
            <b-row>
              <b-col cols="4">
                <b-form-group label="Nama" label-for="name">
                  <b-form-input
                    id="company-name"
                    placeholder="Nama"
                    v-model="company.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="No Telp" label-for="phone">
                  <b-form-input
                    id="company-phone"
                    placeholder="No Telp"
                    v-model="company.phone"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Alamat Lengkap" label-for="address">
                  <b-form-textarea
                    id="company-address"
                    placeholder="Alamat Lengkap"
                    v-model="company.address"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Kelurahan" label-for="village">
                  <b-form-input
                    id="company-village"
                    placeholder="Kelurahan"
                    v-model="company.village"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Kecamatan" label-for="district">
                  <b-form-input
                    id="company-district"
                    placeholder="Kecamatan"
                    v-model="company.district"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Kota / Kabupaten" label-for="city">
                  <b-form-input
                    id="company-city"
                    placeholder="Kota / Kabupaten"
                    v-model="company.city"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Provinsi" label-for="province">
                  <b-form-input
                    id="company-province"
                    placeholder="Provinsi"
                    v-model="company.province"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Negara" label-for="country">
                  <b-form-input
                    id="company-country"
                    placeholder="Negara"
                    v-model="company.country"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Kode Pos" label-for="postal_code">
                  <b-form-input
                    id="company-postal-code"
                    placeholder="Kode Pos"
                    v-model="company.postal_code"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BBadge,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import vSelect from "vue-select";

export default {
  components: {
    ToastificationContent,
    BFormTextarea,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BBadge,
    BContainer,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-warning",
        1: "light-success",
      };

      return (status) => statusColor[status];
    },
  },
  setup() {
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  data() {
    return {
      form: {
        id: "",
        nik: "",
        name: "",
        birthdate: "",
        birthplace: "",
        gender: null,
        gender_name: "",
        phone: null,
        email: "",
        status: null,
        company_id: null,
        verified: 0,
        refId: 0,
        refName: "",
        selectedCustomer: {
          text: '',
          value: 0
        },
        optionsCustomer: [],
      },
      address: {
        address: "",
        village: "",
        district: "",
        city: "",
        province: "",
        country: "",
        postal_code: "",
      },
      company: {
        name: "",
        phone: "",
        address: "",
        village: "",
        district: "",
        city: "",
        province: "",
        country: "",
        postal_code: "",
      },
      images: {
        profile: null,
        identity: null,
        license: null,
        npwp: null,
        company: null,
      },
      permissions: [],
      errors: "",
      errMessage: "",
    };
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
    this.getCustomer();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      const { baseUrl } = $themeConfig.app;
      const imageUri = baseUrl + "image/";

      this.$http.get("customer/" + id).then((response) => {
        let $data = response.data.data;

        this.form.name = $data.name;
        this.form.nik = $data.nik;
        this.form.phone = $data.phone;
        this.form.email = $data.email;
        this.form.gender = $data.gender;
        this.form.gender_name = $data.gender_name;
        this.form.status = $data.status;
        this.form.birthplace = $data.birthplace;
        this.form.birthdate = $data.birthdate;
        this.form.company_id = $data.company_id;
        this.form.verified = $data.verified;
        this.form.refId = $data.ref_id;
        this.form.refName = $data.ref_name;
        // this.form.selectedCustomer.value = $data.ref_id;
        // this.form.selectedCustomer.text = $data.ref_name;

        if ($data.ref_id != 0){

          this.form.selectedCustomer.value = $data.ref_id;
          this.form.selectedCustomer.text = $data.ref_name;
        }

        this.images.profile = imageUri + "customer/" + $data.image;

        if ($data.image_identity != null){
          this.images.identity = imageUri + "user/identity/" + $data.image_identity;
        } 

        if ($data.image_driver_license != null){
          this.images.license = imageUri + "user/license/" + $data.image_driver_license;
        }

        if ($data.image_npwp != null){
          this.images.npwp = imageUri + "user/npwp/" + $data.image_npwp;
        }

        if ($data.image_company_card != null){
          this.images.company = imageUri + "user/company/" + $data.image_company_card;
        }

        
        if ($data.address != null) {
          let $address = $data.address;
          this.address.address = $address.address;
          this.address.village = $address.village;
          this.address.district = $address.district;
          this.address.city = $address.city;
          this.address.province = $address.province;
          this.address.country = $address.country;
          this.address.postal_code = $address.postal_code;
        }

        if ($data.company != null) {
          let $company = $data.company;
          this.company.name = $company.name;
          this.company.phone = $company.phone;
          this.company.address = $company.address;
          this.company.village = $company.village;
          this.company.district = $company.district;
          this.company.city = $company.city;
          this.company.province = $company.province;
          this.company.country = $company.country;
          this.company.postal_code = $company.postal_code;
        }
      });
    },
    updateCustomerPlusValidation() {
      if (this.form.name === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "BellIcon",
            text: "Nama tidak boleh kosong",
            variant: "warning",
          },
        });
      } else {
        const id = this.$route.params.id;
        this.$http
          .post("sales/customer/" + id + "/update", {
            name: this.form.name,
            category_id: this.form.selectedCategory,
            pic: this.form.pic,
            email: this.form.email,
            business_phone: this.form.business_phone,
            personal_phone: this.form.personal_phone,
            fax: this.form.fax,
            website: this.form.website,
            address: this.form.address,
            village: this.form.village,
            district: this.form.district,
            city: this.form.city,
            province: this.form.province,
            country: this.form.country,
            postal_code: this.form.postal_code,
          })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notifikasi",
                icon: "BellIcon",
                text: "Data berhasil diperbaharui",
                variant: "success",
              },
            });
            location.href = "/sales/customer";
            // console.log(response.data.data)
          })
          .catch((errors) => {
            this.errMessage = errors.response.data.message;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notifikasi",
                icon: "BellIcon",
                text: this.errMessage,
                variant: "warning",
              },
            });
            console.log(errors.response);
          });
      }
    },
    deleteCustomer() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/customer/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Data berhasil dihapus",
              variant: "danger",
            },
          });
          location.href = "/sales/customer";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
    getCustomer() {
      this.$http.get("customers?list=1").then((response) => {
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsCustomer.push($data);
        }
      });
    },
    verified() {
      const id = this.$route.params.id;
      this.$http
        .post("customer/" + id + "/verified", {
          verified: 1,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Data berhasil diperbaharui",
              variant: "success",
            },
          });
          location.href = "/customer/" + id;
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    assign() {
      const id = this.$route.params.id;
      this.$http
        .post("customer/" + id + "/assign", {
          ref_id: this.form.selectedCustomer.value,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Data berhasil diperbaharui",
              variant: "success",
            },
          });
          location.href = "/customer/" + id;
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    notAvail(){
      this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Foto tidak tersedia",
              variant: "warning",
            },
          });
    }
  },
};
</script>

<style>
#customercategory option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#customercategory option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>